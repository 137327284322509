import Mux from '@mux/mux-player-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import ReactSwipe from 'react-swipe';

import Icon from 'dpl/common/components/Icon';
import { GDLinkV1 } from 'dpl/common/core';
import PhotoAlbumBullets from 'dpl/components/PhotoAlbumBullets';
import SmartImage from 'dpl/components/SmartImage';
import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import withLazyLoading from 'dpl/decorators/withLazyLoading';
import { noop } from 'dpl/shared/utils';
import { MediaFilePropTypes } from 'dpl/shared/utils/customPropTypes';
import { sendAnalyticsEvent } from 'dpl/util/analytics';

const SWIPE_STYLES = {
  wrapper: { height: '100%', fontSize: 0 }
};

export function MediaCarouselItem({
  fileDetails,
  file,
  smartImageProps,
  dimensions,
  muxRef
}) {
  const { type, analytics, url, alt, urls, href, specificImageProps } = file;

  let component;

  // Parent images which are split into two
  if (urls && urls.length) {
    const { className } = smartImageProps;

    const adjustedClassName = className.replace('min-w-100', '');

    component = (
      <div className="flex">
        {urls.map(currentUrl => (
          <SmartImage
            {...dimensions}
            {...smartImageProps}
            className={adjustedClassName}
            key={currentUrl}
            src={currentUrl}
            width={
              dimensions.width
                ? `${parseInt(dimensions.width, 10) / urls.length}px`
                : null
            }
          />
        ))}
      </div>
    );
  } else {
    component =
      type === 'Video' ? (
        <Mux
          ref={muxRef}
          streamType="on-demand"
          playbackId={file.playback_ids?.[0]?.id}
          style={{
            '--bottom-controls': 'none',
            '--center-seek-forward-button': 'none',
            '--center-seek-backward-button': 'none',
            ...dimensions
          }}
        />
      ) : (
        <SmartImage
          {...dimensions}
          {...smartImageProps}
          {...specificImageProps} // Allows overwriting the smart image if needed. Use sparingly
          alt={alt}
          src={url}
        />
      );
  }

  return (
    <div className="relative h-100 w-100">
      {href ? (
        <GDLinkV1
          href={href}
          onClick={() => {
            if (analytics) {
              sendAnalyticsEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, analytics);
            }
          }}
        >
          {component}
        </GDLinkV1>
      ) : (
        component
      )}
      {fileDetails}
    </div>
  );
}

MediaCarouselItem.propTypes = {
  file: PropTypes.shape(MediaFilePropTypes).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  smartImageProps: PropTypes.object,
  dimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  fileDetails: PropTypes.node,
  muxRef: PropTypes.object.isRequired
};

MediaCarouselItem.defaultProps = {
  smartImageProps: {},
  dimensions: {},
  fileDetails: null
};

class MediaCarousel extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    currentFile: PropTypes.shape(MediaFilePropTypes),
    files: PropTypes.arrayOf(PropTypes.shape(MediaFilePropTypes)),
    /* eslint-enable react/no-unused-prop-types */
    smartImageProps: PropTypes.object,
    dimensions: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string
    }),
    onFileChange: PropTypes.func,
    continuous: PropTypes.bool,
    arrowClassName: PropTypes.string,
    leftArrowClassName: PropTypes.string,
    rightArrowClassName: PropTypes.string,
    arrowSize: PropTypes.string,
    showArrowsOnHover: PropTypes.bool,
    bulletsContainerClassName: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    bulletStyle: PropTypes.object,
    showScrims: PropTypes.bool,
    isInViewport: PropTypes.bool.isRequired,
    setLazyRef: PropTypes.func,
    maxBulletsPadding: PropTypes.number,
    className: PropTypes.string,
    scrimClassName: PropTypes.string,
    renderFileDetails: PropTypes.func
  };

  static defaultProps = {
    setLazyRef: null,
    currentFile: null,
    files: [],
    onFileChange: null,
    continuous: true,
    dimensions: {},
    smartImageProps: {
      Tag: 'img',
      className: 'max-h-100'
    },
    arrowClassName: 'bg-white o-80 br-100 box-shadow-strong silver fw-medium',
    leftArrowClassName: 'ml3',
    rightArrowClassName: 'mr3',
    arrowSize: '40px',
    showArrowsOnHover: false,
    bulletsContainerClassName: 'pv2',
    showScrims: false,
    maxBulletsPadding: 4,
    className: null,
    scrimClassName: null,
    bulletStyle: {},
    renderFileDetails: noop
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let { files, currentFile } = prevState;

    if (
      nextProps.files.length !== files.length ||
      nextProps.files.some((file, idx) => file.id !== files[idx].id)
    ) {
      files = nextProps.files.map((file, arrayIdx) => ({ ...file, arrayIdx }));
      [currentFile] = files;
    }

    if (nextProps.currentFile && currentFile.id !== nextProps.currentFile.id) {
      currentFile =
        files.find(({ id }) => id === nextProps.currentFile.id) || currentFile;
    }

    return {
      files,
      currentFile,
      /**
       * HACK: if ReactSwipe's key prop is set to the alreay displayed image, it
       * still re-renders the image which causes some flash. If we give it 'null',
       * it doesn't
       */
      reactSwipeKey:
        currentFile.id !== prevState.currentFile.id ? currentFile.id : null
    };
  }

  state = {
    currentFile: { arrayIdx: 0 },
    files: []
  };

  swipeEl = createRef();

  muxEl = createRef();

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.currentFile.id !== this.state.currentFile.id ||
      this.props.isInViewport !== nextProps.isInViewport
    );
  }

  get swipeOptions() {
    const { onFileChange, continuous } = this.props;
    const { currentFile, files } = this.state;

    return {
      continuous,
      startSlide: currentFile.arrayIdx,
      transitionEnd: idx => {
        const newCurrentFile = files[idx % files.length];
        if (newCurrentFile) {
          this.setState(
            {
              currentFile: newCurrentFile
            },
            () => {
              onFileChange && onFileChange(newCurrentFile.id);
            }
          );
        }
      }
    };
  }

  handleNextClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.swipeEl.current.next();
    this.muxEl.current?.pause();
  };

  handlePreviousClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.swipeEl.current.prev();
    this.muxEl.current?.pause();
  };

  render() {
    const {
      smartImageProps,
      dimensions,
      continuous,
      arrowClassName,
      leftArrowClassName,
      rightArrowClassName,
      arrowSize,
      showArrowsOnHover,
      bulletsContainerClassName,
      bulletStyle,
      showScrims,
      isInViewport,
      setLazyRef,
      maxBulletsPadding,
      className,
      scrimClassName,
      renderFileDetails
    } = this.props;

    const { reactSwipeKey, currentFile, files } = this.state;

    const numFiles = files.length;

    // HACK: until we have a valid loading state, we at least want to show one
    // SmartImage so it takes up the space and renders its loading animation
    const adjFiles = numFiles > 0 ? files : [{ url: '', arrayIdx: 0 }];

    return (
      <div
        className={classnames('MediaCarousel h-100', className, {
          'hide-child': showArrowsOnHover
        })}
      >
        <div
          className="relative h-100 overflow-hidden"
          style={{
            minHeight: dimensions.height || '1px',
            minWidth: dimensions.width || '1px'
          }}
          ref={setLazyRef}
        >
          {isInViewport && (
            <ReactSwipe
              ref={this.swipeEl}
              style={SWIPE_STYLES}
              className="h-100"
              key={reactSwipeKey}
              swipeOptions={this.swipeOptions}
            >
              {adjFiles.map(file => (
                <div key={file.arrayIdx} className="h-100 relative dib v-top">
                  <div className="flex h-100 items-center justify-center">
                    {Math.abs(file.arrayIdx - currentFile.arrayIdx) <= 2 && (
                      <MediaCarouselItem
                        fileDetails={renderFileDetails(file)}
                        file={file}
                        smartImageProps={smartImageProps}
                        dimensions={dimensions}
                        muxRef={file === currentFile ? this.muxEl : undefined}
                      />
                    )}
                  </div>
                </div>
              ))}
            </ReactSwipe>
          )}
          {numFiles > 1 && (continuous || currentFile.arrayIdx > 0) && (
            <div className="dn db-l">
              {showScrims && (
                <div
                  className={classnames(
                    'scrim--gradient-black-left absolute top-0 left-0 w-50 h-100',
                    scrimClassName
                  )}
                />
              )}
              <button
                type="button"
                className={classnames(
                  'MediaCarousel__arrow flex items-center justify-center transform-center left-0',
                  arrowClassName,
                  leftArrowClassName,
                  { child: showArrowsOnHover }
                )}
                onClick={this.handlePreviousClick}
              >
                <Icon
                  height={arrowSize}
                  name="fetch-chevron-left"
                  width={arrowSize}
                />
              </button>
            </div>
          )}
          {numFiles > 1 &&
            (continuous || currentFile.arrayIdx < files.length - 1) && (
              <div className="dn db-l">
                {showScrims && (
                  <div
                    className={classnames(
                      'scrim--gradient-black-right absolute top-0 right-0 w-50 h-100',
                      scrimClassName
                    )}
                  />
                )}
                <button
                  type="button"
                  className={classnames(
                    'MediaCarousel__arrow flex items-center justify-center transform-center right-0',
                    arrowClassName,
                    rightArrowClassName,
                    { child: showArrowsOnHover }
                  )}
                  onClick={this.handleNextClick}
                >
                  <Icon
                    height={arrowSize}
                    name="fetch-chevron-right"
                    width={arrowSize}
                  />
                </button>
              </div>
            )}
          <div className="absolute bottom-0 right-0 left-0 pb4">
            {typeof currentFile.arrayIdx === 'number' && files.length > 1 && (
              <PhotoAlbumBullets
                imageCount={files.length}
                currentImageIdx={currentFile.arrayIdx}
                containerClassName={bulletsContainerClassName}
                maxBulletsPadding={maxBulletsPadding}
                bulletStyle={bulletStyle}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLazyLoading({
  defaultLazy: true
})(MediaCarousel);
